/**
 * External dependencies.
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { get, isUndefined } from 'lodash';
import classnames from 'classnames';
import { Link } from 'gatsby';

/**
 * Internal dependencies.
 */
import DropdownIcon from '../../icons/dropdown-icon';

import './style.scss';

/**
 * BlogHeader Component.
 *
 * @param {Object} props Component props.
 *
 * @return {jsx}
 */
const BlogHeader = ({ categories, category }) => {
	// console.log(categories)

	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const currentCategorySlug = get(category, 'node[slug]');

	const listClassNames = classnames(
		'blog-header-terms',
		{
			'blog-header-terms--menu-open': isMenuOpen
		}
	);

	const allArticlesClassNames = classnames(
		'blog-header__term-item',
		'blog-header__term-item--all-articles',
		{
			'blog-header__term-item--active': isUndefined(currentCategorySlug)
		}
	);

	return (
		<div className="blog-header">
			<h1 className="blog-header__title">Open Blog</h1>
			<ul className={listClassNames}>
				<li className={`${allArticlesClassNames} hide-for-large`}>
					<Link to={'/blog'} className="blog-header__term-link blog-header__term-link--all-articles">
						All articles
					</Link>
					<button onClick={() => setIsMenuOpen(!isMenuOpen)} className="blog-header__drop-down-button icon-button">
						<DropdownIcon />
					</button>
				</li>
				<ul className="blog-header__term-items">
					<li className={`${allArticlesClassNames} show-for-large`}>
						<Link className="blog-header__term-link" to={`/blog`}>All articles</Link>
					</li>

					{categories.map((term, index) => {

						const liClassNames = classnames(
							'blog-header__term-item',
							{
								'blog-header__term-item--active': (term.slug === currentCategorySlug)
							}
						);

						return (6 > index) ? (
							<li className={liClassNames} key={term.id}>
								<Link className="blog-header__term-link" to={`/blog${term.uri}`}>{term.name}</Link>
							</li>
						) : null;
					})}
				</ul>
			</ul>
		</div>
	);
};

BlogHeader.propTypes = {
	categories: PropTypes.array,
	category: PropTypes.object
};

BlogHeader.defaultProps = {
	categories: [],
	category: {
		node: {}
	}
};

export default BlogHeader;
