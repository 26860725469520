/**
 * External dependencies.
 */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import './style.scss';

/**
 * MultipleAuthors Component.
 *
 * @param {Object} props Component props.
 *
 * @return {jsx}
 */
const MultipleAuthors = ( props ) => {

	const { authors }     = props;
	const numberOfAuthors = authors.length;

	return (
		<span className="article-card__multiple-author">
			<span className="article-card__by"> By </span>
			{
				authors.map( ( author, index ) => {
					let authorName = author.name;

					if ( ( numberOfAuthors - 2 ) >= ( index + 1 )  ) {
						authorName = `${authorName},`;
					}

					return (
						<Fragment key={ `multiple-authors-${index}` }>
							{
								( 0 < index && ( numberOfAuthors - 1 ) === index ) ?
								<span className="article-card__and"> and </span> : null
							}
							<span className="article-card__posted-by"> { ` ${authorName} ` } </span>
						</Fragment>
					);
				} )
			}
		</span>
	);
};

MultipleAuthors.propTypes = {
	authors: PropTypes.array,
};

MultipleAuthors.defaultProps = {
	authors: []
};

export default MultipleAuthors;
