/**
 * External dependencies.
 */
import React from 'react';
import PropTypes from 'prop-types';
// import { useLazyQuery } from '@apollo/react-hooks';

/**
 * Internal dependencies.
 */
import './style.scss';

/**
 * LoadMore Button.
 *
 * @param {Object} props Component props.
 *
 * @return {*}
 */
const LoadMore = ({ allItems, setAllItems, items, setItems }) => {

	// const [pageInfo, setPageInfo] = useState(defaultPageInfo);
	// const [fetchPosts, { loading, data }] = useLazyQuery(QUERY);
	// const posts = data ? data[postKey] : {};

	/**
	 * Set posts.
	 *
	 * @param {Object} posts Posts.
	 *
	 * @return {void}
	 */
	const setPosts = (posts) => {
		// if (!posts || !posts.edges || !posts.pageInfo) {
		// 	return;
		// }

		// const { endCursor } = posts.pageInfo;
		const newPosts = items.concat(allItems.slice(0, 11));
		const newAllPosts = allItems.slice(11);
		setItems(newPosts);
		setAllItems(newAllPosts);

		/**
		 * Workaround for getting endCursor because WPGraphql currently doesn't have a way to fetch from
		 * certain offset, so making two requests for the first time until we extend it in the backend.
		 */
		// if (null === pageInfo.endCursor) {
		// 	loadMoreItems(endCursor);
		// } else {
		// 	setItems(newPosts);
		// 	setLastItemsCount(posts.edges.length);
		// }

		// setPageInfo(posts.pageInfo);
	}

	// useEffect(() => setPosts(posts), [posts]); /* eslint-disable-line */

	/**
	 * Load more items.
	 *
	 * @param {string} endCursor End cursor or offset string.
	 *
	 * @return {void}
	 */
	const loadMoreItems = (endCursor = null) => {
		// const variables = {
		// 	first: perPage,
		// 	last: null,
		// 	after: endCursor,
		// 	before: null,
		// 	hidePostNodeIds: hidePostNodeIds
		// };

		// if (isCategoryPage) {
		// 	variables.categoryName = category.node.name;
		// }

		// fetchPosts({
		// 	variables
		// });
		setPosts()
	};

	return (
		<div className="load-more">
			{/* {pageInfo && pageInfo.hasNextPage ? ( */}
			<button
				className="load-more__button icon-button"
				onClick={() => loadMoreItems()}
			>
				Load more
			</button>
			{/* ) : null} */}
		</div>
	);
};

LoadMore.propTypes = {
	items: PropTypes.array,
	setItems: PropTypes.func,
	setLastItemsCount: PropTypes.func,
	category: PropTypes.object,
	isCategoryPage: PropTypes.bool,
	perPage: PropTypes.number,
}

export default LoadMore;
