/**
 * External dependencies.
 */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import classnames from 'classnames';
import { getComma } from '../../../utils/functions'
/**
 * Internal dependencies.
 */
import Image from './../../image/index';
import MultipleAuthors from './../multiple-authors';
import { getFormattedDate, sanitize } from '../../../utils/functions';

import './style.scss';

/**
 * ArticleCard Component.
 *
 * @param {Object} props Component props.
 *
 * @return {jsx}
 */
const ArticleCard = ({ post, animate = true, className, isSinglePage, isWide = true }) => {

	const { author, large, medium, thumbnail, categories, date, title, multipleAuthors, owHidePublishDate } = post;

	const containerClassName = classnames(
		'article-card',
		className,
		{
			'animated slideInUp': animate
		}
	);

	const image = isWide ? large : medium;
	const featuredImage = image ? image.node : null;

	return (
		<article className={containerClassName}>
			<figure className="article-card__header">
				<Image {...featuredImage} placeholder={thumbnail ? thumbnail.node : {}} />
			</figure>
			<div className="article-card__footer">
				<div className="article-card__footer-top">
					{!isEmpty(categories) && !isEmpty(categories.edges) ? (
						categories.edges.map((edge, index) => (
							<Fragment key={`term-${index}`}>
								<span className="article-card__term">{edge.node.name}</span>
								{getComma(categories.edges, index)}
							</Fragment>
						))
					) : null}
					{isSinglePage ? (
						<h1 className="article-card__title" dangerouslySetInnerHTML={{ __html: sanitize(title) }} />
					) : (
						<h2 className="article-card__title" dangerouslySetInnerHTML={{ __html: sanitize(title) }} />
					)}
				</div>
				<div className="article-card__meta">
					<time className="article-card__posted-on">{!owHidePublishDate && getFormattedDate(date)}</time>
					{multipleAuthors && multipleAuthors.authors && <MultipleAuthors authors={multipleAuthors.authors} />}
					{(!multipleAuthors || !multipleAuthors.authors) && author.node.name ? (
						<span className="article-card__author">
							<span className="article-card__by"> By </span>
							<span className="article-card__posted-by">{author.node.name}</span>
						</span>
					) : null}
				</div>
			</div>
		</article>
	);
};

ArticleCard.propTypes = {
	post: PropTypes.object,
	animate: PropTypes.bool,
	className: PropTypes.string,
	isSinglePage: PropTypes.bool
};

ArticleCard.defaultProps = {
	post: {},
	animate: true,
	className: '',
	isSinglePage: false
};

export default ArticleCard;
