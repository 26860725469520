/**
 * External dependencies.
 */
import gql from 'graphql-tag';

const GET_POSTS = gql`
query GET_POSTS($first: Int, $last: Int, $after: String, $before: String, $hidePostNodeIds: [ID]) {
	posts(first: $first, last: $last, after: $after, before: $before, where: {onlySticky: false, notIn: $hidePostNodeIds }) {
		pageInfo {
			hasNextPage
			hasPreviousPage
			startCursor
			endCursor
		}
		edges {
			node {
				id
				databaseId
				title
				uri
				date
				categories {
					edges {
						node {
							name
							uri
						}
					}
				}
				author {
					node {
						name
						uri
					}
				}
				large: featuredImage {
					node {
						sourceUrl(size: POST_THUMBNAIL_LANDSCAPE)
						title
						altText
						mediaDetails {
							width
							height
						}
						srcSet
					}
				}
				medium: featuredImage {
					node {
						sourceUrl(size: MEDIUM_LANDSCAPE)
						title
						altText
						mediaDetails {
							width
							height
						}
						srcSet
					}
				}
				thumbnail: featuredImage {
					node {
						sourceUrl(size: THUMBNAIL)
						title
						altText
						mediaDetails {
							width
							height
						}
						srcSet
					}
				}
				multipleAuthors {
					authors {
						name
						uri
					}
				}
			}
		}
	}
}
`;

export default GET_POSTS;
