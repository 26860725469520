/**
 * External dependencies.
 */
import React from 'react';
import PropTypes from 'prop-types';

/**
 * Internal dependencies.
 */
import Layout from "../../components/layout";
import Blogs from "../../components/blogs";
import SEO from "../../components/seo";
import { getOgImage } from "./../../utils/functions";

/**
 * Blog Template Page.
 *
 * @TODO Change as per the design later.
 *
 * @param {Object} props Props
 *
 * @return {*}
 */
const ArchiveSingle = (props) => {

	const {
		pageContext: {
			singleCategory,
			singleCategory: {
				node: { name }
			},
			categories,
			seo,
			uri
		}
	} = props;

	const openGraphImage = getOgImage(seo);

	const allPostsEdges = singleCategory.node.posts.edges;

	const initialPosts = allPostsEdges.slice(0, 12);
	const allPosts = allPostsEdges.slice(12);


	return (
		<Layout title={name} uri={uri} className="archive archive-single">
			<SEO
				title={name}
				seoData={seo}
				uri={uri}
				header={{ siteTitle: 'OpenWeb' }}
				openGraphImage={openGraphImage}
			/>
			<Blogs
				category={singleCategory}
				initialPosts={allPostsEdges}
				allPosts={allPosts}
				categories={categories}
			/>
		</Layout>
	)
};

ArchiveSingle.propTypes = {
	pageContext: PropTypes.object,
	uri: PropTypes.string,
};

export default ArchiveSingle;
