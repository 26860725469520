/**
 * External dependencies.
 */
import React, { useState, createRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { animateScroll } from 'react-scroll';
import { isEmpty } from 'lodash';

/**
 * Internal dependencies.
 */
import LoadMore from './../load-more/index';
import BlogHeader from './blog-header';
import BlogList from './blog-list/index';
// import Subscribe from './subscribe';
import GET_POSTS_BY_CATEGORY from './queries/get-posts-by-category';
import GET_POSTS from './queries/get-posts';
import ContactForm from '@blocks/contact-form';

/**
 * Blogs Component.
 *
 * @param {Object} props Component props.
 *
 * @return {jsx}
 */
const Blogs = ({ initialPosts, allPosts, categories, pageInfo, category, form, tagName, tagParent, hidePostNodeIds }) => {
	// console.log(initialPosts)
	const isCategoryPage = category && !isEmpty(category.node);
	const perPage = isCategoryPage ? 12 : 11;
	const [items, setItems] = useState(initialPosts);
	const [allItems, setAllItems] = useState(allPosts)
	const [lastItemsCount, setLastItemsCount] = useState(0);
	const isFirstSet = (items.length <= perPage);
	// console.log(items);
	// console.log(allItems);
	const canShowLoadMore = (!isEmpty(items) && items.length < allItems.length);
	const ref = createRef();
	const refIndex = lastItemsCount ? (items.length - lastItemsCount) : 0;
	const POST_QUERY = isCategoryPage ? GET_POSTS_BY_CATEGORY : GET_POSTS;

	/**
	 * Scroll to the first article from the last loaded batch
	 * when load-more button was clicked.
	 */
	const scrollToArticle = () => {
		if (lastItemsCount && ref.current) {
			animateScroll.scrollTo(ref.current.offsetTop - 120);
		}
	}

	useEffect(() => scrollToArticle(), [items]); /* eslint-disable-line */

	return (
		<>
			<BlogHeader categories={categories} category={category} />
			<BlogList
				posts={items}
				animate={!isFirstSet}
				ref={ref}
				refIndex={refIndex}
			/>
			{canShowLoadMore ? (
				<LoadMore
					allItems={allItems}
					setAllItems={setAllItems}
					items={items}
					setItems={setItems}
					setLastItemsCount={setLastItemsCount}
					category={category}
					isCategoryPage={isCategoryPage}
					perPage={perPage}
					hidePostNodeIds={hidePostNodeIds}
					QUERY={POST_QUERY}
					pageInfo={pageInfo}
				/>
			) : null}
			{/* <Subscribe form={form} tagName={tagName} tagParent={tagParent} /> */}
			<ContactForm />
		</>
	);
};

Blogs.propTypes = {
	initialPosts: PropTypes.array,
	categories: PropTypes.array,
	category: PropTypes.object,
	form: PropTypes.object,
	tagName: PropTypes.string,
	tagParent: PropTypes.string
};

Blogs.defaultProps = {
	initialPosts: [],
	categories: [],
	form: {},
	tagName: '',
	tagParent: ''
}

export default Blogs;
