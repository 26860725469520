/**
 * External dependencies.
 */
import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import classnames from 'classnames';
import { Link } from 'gatsby';

/**
 * Internal dependencies.
 */
import ArticleCard from '../article-card';
import { postSlug } from './../../../utils/functions';

import './style.scss';

/**
 * BlogList Component.
 *
 * @param {Object} props Component props.
 *
 * @return {jsx}
 */
const BlogList = forwardRef( ( { posts, animate = true, className, refIndex }, ref ) => {

	if ( isEmpty( posts ) || ! posts.length ) {
		return null;
	}

	const containerClass = classnames( 'blog-list', className );

	return (
		<div className={ containerClass }>
			{ posts.map( ( post, index ) => {
				const isWide = ( 0 === index % 11 );
				const articleClass = isWide ? 'article-card--wide' : '';
				const listClass = classnames( 'blog-list__item', { 'blog-list__item--wide': isWide } );

				return (
					<div ref={ index === refIndex ? ref : null } key={ `${ post.node.id }-${ index }` } className={ listClass }>
						<Link className="blog-list__card-link" to={ postSlug( post.node.uri ) }>
							<ArticleCard
								post={ post.node }
								animate={ animate }
								className={ articleClass }
								isWide={ isWide }
							/>
						</Link>
					</div>
				);
			} ) }
		</div>
	);
} );

BlogList.propTypes = {
	posts: PropTypes.array,
	animate: PropTypes.bool,
	className: PropTypes.string,
	refIndex: PropTypes.number
};

BlogList.defaultProps = {
	posts: [],
	animate: true
};

export default BlogList;
